require('@pages/comunicationals/styles.scss');

const React = require('react');
const View = require('@pages/comunicationals/view');
const hydrate = require('nordic/hydrate');
const { AndesProvider } = require('@andes/context');
const { loadableReady } = require('nordic/lazy');

const props = window.__PRELOADED_STATE__;

loadableReady(() => {
  hydrate(
    <AndesProvider locale={props.locale}>
      <View {...props} />
    </AndesProvider>,
    document.getElementById('root-app'),
  );
});
