const React = require('react');
const { useEffect } = require('react');
const PropTypes = require('prop-types');
const Head = require('nordic/head');
const { Page } = require('nordic/page');
const Script = require('nordic/script');
const Style = require('nordic/style');
const serialize = require('serialize-javascript');
const MeliGA = require('nordic/analytics/meli-ga');
const MelidataTrack = require('nordic/melidata/melidata-track');
const TrackingService = require('@services/Tracking/TrackingService');
const MelidataResource = require('@services/Tracking/resources/Melidata');
const MeliGaResource = require('@services/Tracking/resources/MeliGA');
const { getTrackingPath } = require('@services/Tracking/utils');
const { seoDescription, seoTitleTemplate } = require('@utils/helpers');
const Hotjar = require('../../components/Hotjar');
const OpenGraph = require('../../components/OpenGraph');
const RedirectApp = require('redirect-app-web');

const { componentListComunicationals } = require('@utils/componentList');
const Reader = require('../../components/Reader');
const { LandingProvider } = require('@splinter/context');

const View = ({
  dataLanding,
  siteId,
  lowEnd,
  deviceType,
  webView,
  isLegacyBrowser,
  platformId,
  nativeAppName,
  landingType,
  googleTagManager,
  pixelId,
  xExperimentName,
  xExperimentVariant,
  hotjar,
  xDebugHotjar,
  d2id,
  dealPrintId,
  siteName,
  serverTime,
  webp,
  lazyLoad,
  showDealParam,
  osVersion,
  osName,
  xCanonical,
  cssInline,
  openInApp,
  locale,
  dpr,
  platformType,
  isIndexable,
}) => {
  const preloadedState = {
    dataLanding,
    siteId,
    lowEnd,
    deviceType,
    webView,
    platformId,
    nativeAppName,
    landingType,
    googleTagManager,
    pixelId,
    xExperimentName,
    xExperimentVariant,
    hotjar,
    xDebugHotjar,
    d2id,
    dealPrintId,
    siteName,
    serverTime,
    webp,
    lazyLoad,
    showDealParam,
    osVersion,
    osName,
    xCanonical,
    cssInline,
    locale,
    platformType,
    dpr,
  };
  /* istanbul ignore next */
  useEffect(() => {
    TrackingService.sendAllTracks({ dataLanding, dealPrintId, landingType, skeleton: null, platformId, siteId });
  }, [dataLanding, dealPrintId, landingType, platformId, siteId]);

  const { name, theme, openGraph, seo, nativeTitle } = dataLanding;
  const seoTitle = seo?.meta?.title || seoTitleTemplate(name, siteId, siteName);
  const defaultTitle = webView ? nativeTitle : seoTitle;
  const title = defaultTitle || name;
  const wrapperClassName = theme ? `${theme} hub` : 'hub';
  const defaultDescription = seoDescription(name, siteId);
  const description = seo?.meta?.description ?? defaultDescription;
  const canonical = xCanonical || seo.canonical;
  const experiment = { [xExperimentName]: xExperimentVariant };
  const dimensions = { melidataExperiments: JSON.stringify([experiment]) };
  const pathDeals = MeliGaResource.generateDynamicPath(dataLanding, landingType);
  const trackingPath = getTrackingPath(pathDeals);
  const { meliGA, melidata } = trackingPath;
  const meliDataPath = melidata.deals;
  const trackingMD = {
    dynamicInfo: MelidataResource.generateDynamicEventData(dataLanding, landingType),
    context: 'normal-landing',
  };

  return (
    <Page name="comunicationals" className={wrapperClassName} state={preloadedState}>
      {openInApp && openInApp.shouldRedirect && <RedirectApp {...openInApp} />}
      <MeliGA
        siteId={siteId}
        platform={platformId}
        section={meliGA.section.deals}
        page={meliGA.page.deals}
        dimensions={xExperimentName ? dimensions : undefined}
        business={meliGA.business}
        pageVertical={meliGA.pageVertical}
        secure
        isDeferred
      />
      <MelidataTrack path={meliDataPath} experiments={xExperimentName ? experiment : undefined} isDeferred />
      <Hotjar {...(dataLanding.hotjar || hotjar)} debug={xDebugHotjar} d2id={d2id} isHub />

      <Head>
        <title>{title}</title>
        <link rel="preconnect" href="https://js-agent.newrelic.com" />
        <link rel="preconnect" href="https://connect.facebook.net" />
        {(webView || dataLanding.clientSide) && (
          <Script src="@frontend-performance/image-lazy-loading/src.js" on="now" inline />
        )}
        {canonical && <link rel="canonical" href={canonical} data-head-react="true" />}
        <meta name="description" content={description} />
        {isIndexable === false && <meta name="robots" content="noindex" />}
        {openGraph && (
          <OpenGraph
            ogUrl={dataLanding.url}
            title={openGraph.title}
            ogImage={openGraph.image}
            ogLocale={locale}
            description={openGraph.description}
            ogSiteName={siteId}
          />
        )}
        {
          // This workaround enable cssInline on webViews
          Array.isArray(cssInline) && cssInline.length && (
            <Style renderChildrenInPlace={false}>{cssInline.join(' ')}</Style>
          )
        }
      </Head>

      <LandingProvider
        webp={webp}
        dpr={dpr}
        lowEnd={lowEnd}
        lazyLoad={lazyLoad}
        deviceType={deviceType}
        webView={webView}
        showDealParam={showDealParam}
        landingData={dataLanding}
        serverTime={serverTime}
        pixelId={pixelId}
        googleTagManager={googleTagManager}
        siteId={siteId}
        osVersion={osVersion}
        osName={osName}
        uuid={dealPrintId}
        trackingData={trackingPath}
        trackingMD={trackingMD}
        platformType={platformType}
        title={title}
        nativeAppName={nativeAppName}
      >
        <Reader
          landingData={dataLanding}
          webView={webView}
          deviceType={deviceType}
          componentList={componentListComunicationals}
        />
      </LandingProvider>

      <Style href="comunicationals.css" critical />
      <Script>
        {`
            window.__PRELOADED_STATE__ = ${serialize(preloadedState, { isJSON: true })};
          `}
      </Script>

      {isLegacyBrowser && <Script src="polyfills.js" />}
      <noscript></noscript>
    </Page>
  );
};

View.propTypes = {
  siteId: PropTypes.string.isRequired,
  landingType: PropTypes.string.isRequired,
  deviceType: PropTypes.string,
  dataLanding: PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    site: PropTypes.shape({
      analytics: PropTypes.string.isRequired,
      seo_text: PropTypes.string,
    }).isRequired,
    seo: PropTypes.shape({
      meta: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      canonical: PropTypes.string,
    }).isRequired,
    components: PropTypes.arrayOf(
      PropTypes.shape({
        component_name: PropTypes.string,
      }),
    ).isRequired,
    hotjar: PropTypes.shape({
      id: PropTypes.number.isRequired,
      traffic: PropTypes.shape({
        from: PropTypes.number.isRequired,
        to: PropTypes.number.isRequired,
      }),
    }),
  }).isRequired,
  lowEnd: PropTypes.bool,
  webView: PropTypes.bool.isRequired,
  googleTagManager: PropTypes.string.isRequired,
  xExperimentName: PropTypes.string,
  xExperimentVariant: PropTypes.string,
  isLegacyBrowser: PropTypes.bool.isRequired,
  platformId: PropTypes.string.isRequired,
  hotjar: PropTypes.shape({}).isRequired,
  xDebugHotjar: PropTypes.bool.isRequired,
  d2id: PropTypes.string.isRequired,
  dealPrintId: PropTypes.string.isRequired,
  dpr: PropTypes.string,
  platformType: PropTypes.string,
};

View.defaultProps = {
  deviceType: null,
  lowEnd: false,
  xExperimentName: null,
  xExperimentVariant: null,
  dpr: null,
  platformType: null,
};

module.exports = View;
